import React, {useContext, createContext} from 'react'

export const AddEventContext = createContext({});

export function AddEventProvider({children}) {
   const [activeStep, setActiveStep] = React.useState(0);
   const [statusForm, setStatusForm] = React.useState([]);
   const [eventDTO, setEventDTO] = React.useState([]);

   return <AddEventContext.Provider value={{ activeStep, setActiveStep, statusForm, setStatusForm, eventDTO, setEventDTO}}>{children}</AddEventContext.Provider>
}
export function useAddEvent() {
   const context = useContext(AddEventContext);
 
   return context;
 }