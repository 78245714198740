import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import api from '../services/api'
import { format, parseISO } from 'date-fns'
import * as qs from 'qs';

const EventContext = createContext({});

export const EventProvider = ({ children }) => {
    const [events, setEvents] = useState([]);
    const [update, setUpdate] = useState(true);
    const total = useRef(0)

    async function getEvents() {
        let query = qs.stringify({
            filters: {
                deleted: {
                    $eq: false
                }
            },
            populate: {
                plans: {
                    populate: '*',
                },
                collectors: {
                    populate: '*'
                }
            },
            sort: ['createdAt:desc'],
            encodeValuesOnly: true,

        })
        try {
            const allEvents = await api.get(`/events?${query}`)
            if (allEvents.status === 200) {
                allEvents.data.data.map((event) => {
                    event.attributes.end_subscription = format(parseISO(event.attributes.end_subscription), 'dd/MM/yy')
                    event.attributes.date_initial = format(parseISO(event.attributes.date_initial), 'dd/MM/yy')
                    event.attributes.count_subscribers = `${event.attributes.count_subscribers}`
                    event.attributes.isActive = event.attributes.isActive === true ? 'Aberto' : 'Encerrado'
                    return
                })
                total.current = allEvents.data.meta.pagination.total
                setEvents(allEvents.data.data)
            }
        } catch (error) {
            return error
        }
    }


    async function deleteEvent(eventId) {
        try {
            const event = await api.put('/events/' + eventId, { data: { deleted: true } })
            if (event.status === 200) {
                setEvents(events.filter((value, index, arr) => { return value.id !== eventId }))
            }
        } catch (error) {
            return error
        }
    }

    useEffect(() => {
        if (update) {
            getEvents()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    return (
        <EventContext.Provider
            value={{ events, deleteEvent, setUpdate, getEvents, total }}>
            {children}
        </EventContext.Provider>
    );
};

export function useEvent() {
    const context = useContext(EventContext);

    return context;
}
export default EventContext;