import { useEffect } from "react";
import DrawerMenu from "../components/DrawerMenu";
import { createTheme, ThemeProvider } from '@mui/material/styles';



const GradientTheme = createTheme({
    components: {
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'success'},
                    style: { display: 'flex', 
                             flexDirection: 'row',
                             alignItems:'center', 
                             color: 'white', 
                             justifyContent: 'space-around', 
                             textAlign:'center', 
                             width:'100%',
                             height: '100%',
                             background: 'linear-gradient(to left , #66BB6A, #43A047)'
                            }
                },
                {
                    props: { variant: 'info'},
                    style: { display: 'flex', 
                             flexDirection: 'row',
                             alignItems:'center', 
                             color: 'white', 
                             justifyContent: 'space-around', 
                             textAlign:'center', 
                             width:'100%',
                             height: '100%',
                             background: 'linear-gradient(to right , #1A73E8, #49a3f1)'
                            }
                },
                {
                    props: { variant: 'secondary'},
                    style: { display: 'flex', 
                             flexDirection: 'row',
                             alignItems:'center', 
                             color: 'white', 
                             justifyContent: 'space-around', 
                             textAlign:'center', 
                             width:'100%',
                             height: '100%',
                             background: 'linear-gradient(to left , #747b8a, #495361)'
                            }
                },
                {
                    props: { variant: 'danger'},
                    style: { display: 'flex', 
                             flexDirection: 'row',
                             alignItems:'center', 
                             color: 'white', 
                             justifyContent: 'space-around', 
                             textAlign:'center', 
                             width:'100%',
                             height: '100%',
                             background: 'linear-gradient(to left , #EF5350, #E53935)'
                            }
                }
            ]
        },
    }
  });

export default function InitalPage(props){

    useEffect(() => {
        document.title = "CRM - Eventos AGIP" 
      },[])

    return(
        <ThemeProvider theme={GradientTheme}>
        <DrawerMenu title={props.title}>
            {props.screen}
        </DrawerMenu>
        </ThemeProvider>
    )
}


