import React, { createContext, useState,  useContext } from 'react';

const SubscribersContext = createContext({});

export const SubscribersProvider = ({ children }) => {
    const [subscriber, setSubscriber] = useState([]);    

    return (
      <SubscribersContext.Provider
        value={{ subscriber, setSubscriber }}>
        {children}
      </SubscribersContext.Provider>
    );
  };
  
  export function useSubscriber() {
    const context = useContext(SubscribersContext);
  
    return context;
  }
export default SubscribersContext;