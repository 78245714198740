/* eslint-disable array-callback-return */
import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Stack, IconButton,Collapse } from "@mui/material"

import ModeEdit from '@mui/icons-material/ModeEdit'
import Delete from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

function RowCollpase(props) {
    const { row, columns, edit, deactive, payment } = props;
    const [ open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
            <TableRow hover key={row.id}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)  }
                    >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {columns.map((column) => {
                    const value = row.attributes[column.accessor];
                    if(value) return (
                        <TableCell key={column.accessor}>
                            {value}
                        </TableCell>
                    );
                    if(column.Header === 'Ações')return (
                        <TableCell key={column.accessor}>
                          <Stack direction="row" spacing={0}>
                            <IconButton aria-label="edit" color="primary" onClick={() => edit(row) }>
                              <ModeEdit />
                            </IconButton>
                            <IconButton aria-label="view" color="success" onClick={() => payment(row) } >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton aria-label="edit" color="error" onClick={() => deactive(row) } >
                              <Delete />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      )
                })}
            </TableRow>
            <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                    Instituição: {row.attributes.company.data.attributes.social_reason}
                    </Typography>
                    <Table size="small" aria-label="purchases">
                    <TableHead>
                        <TableRow>
                        <TableCell>CNPJ</TableCell>
                        <TableCell>Fantasia</TableCell>
                        <TableCell>Endereço</TableCell>
                        <TableCell>Cep</TableCell>
                        <TableCell>Cidade</TableCell>
                        <TableCell>Bairro</TableCell>
                        <TableCell>E-mail</TableCell>
                        <TableCell>Telefone</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={row.attributes.company.data.id}>
                            <TableCell component="th" scope="row">
                            {row.attributes.company.data.attributes.document}
                            </TableCell>
                            <TableCell>{row.attributes.company.data.attributes.trade}</TableCell>
                            <TableCell>{row.attributes.company.data.attributes.address}</TableCell>
                            <TableCell>{row.attributes.company.data.attributes.cep}</TableCell>
                            <TableCell>{row.attributes.company.data.attributes.city}</TableCell>
                            <TableCell>{row.attributes.company.data.attributes.district}</TableCell>
                            <TableCell>{row.attributes.company.data.attributes.email}</TableCell>
                            <TableCell>{row.attributes.company.data.attributes.phone}</TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </Box>
                <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                    Inscritos
                    </Typography>
                    <Table size="small" aria-label="purchases">
                    <TableHead>
                        <TableRow>
                        <TableCell>Nome Completo</TableCell>
                        <TableCell>CPF</TableCell>
                        <TableCell>Cargo</TableCell>
                        <TableCell>E-mail</TableCell>
                        <TableCell>Whatsapp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {row.attributes.subscribers.data.map((subscriberRow,index) => (
                        <TableRow key={subscriberRow.id}>
                            <TableCell component="th" scope="row">
                            {subscriberRow.attributes.name}
                            </TableCell>
                            <TableCell>{subscriberRow.attributes.document}</TableCell>
                            <TableCell>{subscriberRow.attributes.office}</TableCell>
                            <TableCell>{subscriberRow.attributes.email}</TableCell>
                            <TableCell>{subscriberRow.attributes.whatsapp}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </Box>
                </Collapse>
            </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
export default function TableCollapsible(props) {
    const {columns, rows, edit, deactive, payment } = props 
    

    const variants = [{
            type: 'success',
            color: 'linear-gradient(to left , #66BB6A, #43A047)',
        },
        {
            type: 'info',
            color: 'linear-gradient(to right , #1A73E8, #49a3f1)'
        },
        {   
            type: 'secondary',
            color: 'linear-gradient(to left , #747b8a, #495361)'
}]
    function isVariant(){
        const [ variant ] =  variants.filter(variant => variant.type === props.variant)
        return variant.color
    }

    return (
        <Box sx={{ width: '100%', overflow: 'hidden'}} >
        <Box mx={'auto'} 
            sx={{width: '92%', height: '30px', display:'flex', color: 'white', justifyContent: 'start', 
            textAlgin: 'left', padding: 3, background: isVariant(),
            borderRadius: 2}}>
            <Typography variant={'h6'} sx={{alignSelf: 'center'}}>{props.title}</Typography>
        </Box>
        <TableContainer component={Paper} elevation={4} sx={{ justifyContent: 'center', width: '90%', margin: '0 auto 10px auto' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell> {' '} </TableCell>
                {columns.map((column,index) => (
                  <TableCell
                    key={index}
                    style={{ minWidth: column.width }}
                  >
                    {column.Header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {rows.length > 0 ? rows.map((row,index) => {
                  return (
                    <React.Fragment>
                        <RowCollpase key={index} row={row} columns={columns} {...props}/>
                    </React.Fragment>
                  );
                }) : null}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
    )
}