import React, { useEffect, useRef, useState } from "react"
import {
    Box, CssBaseline, Grid, CircularProgress, TableBody, TextField, Button,
    Table, TableHead, TableCell, Snackbar, FormControl, Stack, InputLabel, Select,
    MenuItem, TableRow, Pagination, Modal, TableContainer, Paper, IconButton,
    Typography
} from "@mui/material"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MuiAlert from '@mui/material/Alert'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEvent } from '../../contexts/event.context'
import { format, parseISO } from 'date-fns'
import TableInfo from '../../components/TableInfo'
import api, { baseURL } from '../../services/api'
import * as qs from 'qs';
import ScannerCode from "../../components/ScannerCode";



export default function Frequencia() {
    const [stateAlert, setStateAlert] = useState({ status: false, type: 'success', msg: '' });
    const [eventReader, setEventReader] = useState()
    const [frequencies, setFrequencies] = useState([])
    const [subcriberCode, setSubscriberCode] = useState()
    const selectedEvent = useRef()
    const inputReader = useRef(null)
    const [timer, setTimer] = useState(null)
    const subscriberPrev = useRef(null)
    const [pagination, setPagination] = useState([])
    const { events, getEvents } = useEvent()
    const page = useRef(1)
    const query = useRef()
    const [offlineMode, setOfflineMode] = useState(false);
    const [offlineQueue, setOfflineQueue] = useState([]);
    const offlineList = [];
    const [resultImport, setResultImport] = useState([])
    const [camera, setCamera] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);
    const [modalimport, setimportmodal] = useState(false)
    const [modalimport2, setimportmodal2] = useState(false)
    const [readAt, setReadAt] = useState();
    const formImport = new FormData();

    const onDetected = result => {
        clearTimeout(timer)

        const newTimer = setTimeout(async () => {
            await addFrequencies({ result: result })
        }, 500)

        setTimer(newTimer)
    };


    query.current = qs.stringify({
        filters: {
            event: selectedEvent.current
        },
        sort: ['readAt:desc'],
        populate: {
            event: {
                fields: ['name']
            },
            subscriber: {
                fields: ['id', 'badge', 'email']
            },
            collectors: {
                populate: true
            }
        },
        pagination: {
            pageSize: 10,
            page: page.current,
        },
        encodeValuesOnly: true
    })


    const columns = [
        { Header: "Crachá", accessor: "badge", width: "25%" },
        { Header: "Email", accessor: "email", width: "25%" },
        { Header: "Horário Leitura", accessor: "readAt", width: "30%" }
    ];

    const columnsQueue = [
        { Header: "Evento", accessor: "event", width: "25%" },
        { Header: "ID", accessor: "id", width: "25%" },
        { Header: "Horário Leitura", accessor: "readAt", width: "30%" }
    ];


    async function addFrequenciesOffilne(data) {
        try {
            if (offlineQueue.filter(cods => cods.id === data.id).length > 0) {
                console.log(`${data.id} já registrado`)
            }
            const registerFrequency = await api.post('/frequency/reader', { data: data })
            if (registerFrequency.status == 200) {
                console.log(registerFrequency.data.data)
            }
        } catch (err) {
            const { data, error } = err?.response?.data
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `${error.message}` })
            console.log(err)
        }
    }

    async function addFrequencies({ result }) {
        console.log({ offlineMode })
        let dataID = result
        console.log(result, subscriberPrev.current, result == subscriberPrev.current)
        if (!selectedEvent.current) {
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `Selecione um evento para iniciar a leitura` })
            return
        } else if (result <= 0 || result.length <= 0) {
            setSubscriberCode("")
            inputReader.current.focus();
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `Código Inválido` })
            return
        } else if (result == subscriberPrev.current) {
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `Inscrito já registrado` })
            setSubscriberCode("")
            inputReader.current.focus();
            return
        } else if (offlineMode) {
            if (offlineList.length > 0 && offlineList.find(cods => cods.id == result) != undefined) {
                console.log(`${result} já registrado`)
                setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `Inscrito já registrado` })
                setSubscriberCode("")
                inputReader.current.focus();
                return
            } else if (offlineList.find(cods => cods.id == dataID) == undefined) {
                setOfflineQueue((prevQueue) => [...prevQueue, { event: selectedEvent.current, id: result, readAt: new Date().toISOString() }]);
                offlineList.push({ event: selectedEvent.current, id: result, readAt: new Date().toISOString() })
                setStateAlert({ ...stateAlert, status: true, type: 'success', msg: `Inscrito registrado` })
                setSubscriberCode("")
                console.log(offlineQueue, offlineQueue.length, offlineList)
                subscriberPrev.current = result
                inputReader.current.focus();
                return
            }
        } else if (frequencies.length > 0 && frequencies.find(cods => cods.attributes.subscriber.data.id == result) != undefined) {
            console.log(`${result} já registrado`)
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `Inscrito já registrado` })
            setSubscriberCode("")
            inputReader.current.focus();
            return
        }
        try {
            const registerFrequency = await api.post('/frequency/reader', { data: { "event": selectedEvent.current, "id": result, "readAt": new Date().toISOString() } })
            if (registerFrequency.status === 200) {
                subscriberPrev.current = result
                setStateAlert({ ...stateAlert, status: true, type: 'success', msg: `Participante registrado com sucesso!` })
                handleFrequencies()
                setSubscriberCode("")
                inputReader.current.focus();
                return
            }
        } catch (err) {
            console.log(err, offlineMode)
            const { data, error } = err?.response?.data
            setSubscriberCode("")
            inputReader.current.focus();
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `${error.message}` })
        }
    }

    async function handleFrequencies() {
        let params = {
            filters: {
                event: selectedEvent.current || eventReader
            },
            sort: ['readAt:desc'],
            populate: {
                event: {
                    fields: ['name']
                },
                subscriber: {
                    fields: ['id', 'badge', 'email']
                },
                collectors: {
                    populate: true
                }
            },
            pagination: {
                pageSize: 10,
                page: page.current,
            },
            encodeValuesOnly: true
        }
        query.current = qs.stringify(params, {
            skipNulls: true,
            skipUndefined: true,
            skipEmptyStrings: true
        })
        try {
            const allFrequencies = await api.get(`/frequencies?${query.current}`)
            setPagination(allFrequencies.data.meta.pagination)
            let subs = []
            if (allFrequencies.status === 200) {
                subs = [...allFrequencies.data.data]
                // eslint-disable-next-line array-callback-return
                subs.map((subscriber, index) => {
                    subs[index].attributes.badge = subs[index].attributes.subscriber.data.attributes.badge
                    subs[index].attributes.email = subs[index].attributes.subscriber.data.attributes.email
                    subs[index].attributes.readAt = subs[index].attributes?.readAt ? format(parseISO(subs[index].attributes.readAt), 'dd/MM/yy HH:mm') : '-----'
                })
                console.log(allFrequencies.data.data)
                setFrequencies([...allFrequencies.data.data])
            }
        } catch (erro) {
            console.log(erro)
        }
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    useEffect(() => {
        // Verifica a conexão com a API
        const checkConnection = () => {
            const isOnline = navigator.onLine;
            setOfflineMode(!isOnline);
        };
        getEvents();
        window.addEventListener('online', checkConnection);
        window.addEventListener('offline', checkConnection);

        return () => {
            window.removeEventListener('online', checkConnection);
            window.removeEventListener('offline', checkConnection);
        };
    }, []);


    // Função para enviar a fila de dados offline quando a conexão é restabelecida
    const sendOfflineData = async () => {
        offlineQueue.forEach((data) => {
            console.log({ data });
            addFrequenciesOffilne(data)
        });
        setOfflineQueue([]);
        offlineList.length = 0;
        console.log(offlineList)
    };

    useEffect(() => {
        // Verifica se há uma fila de dados offline para enviar quando a conexão é restabelecida
        if (!offlineMode && offlineQueue.length > 0) {
            sendOfflineData();
            handleFrequencies();
        }
    }, [offlineMode]);

    useEffect(() => {
        if (inputReader.current !== null) {
            inputReader.current.focus();
        }
        handleFrequencies()
    }, []);

    const inputAddSubscriber = e => {
        clearTimeout(timer)

        const newTimer = setTimeout(() => {
            addFrequencies({ result: e.target.value })
        }, 500)

        setTimer(newTimer)
    }

    async function handleImport(event) {
        const files = event.target.files
        console.log(files)
        formImport.append('file', files[0])
        console.log(formImport)
    }

    async function generateCert({ event, document }) {
        try {
            const certificate = await api.post('/certificates', { data: { document, event } })
            if (certificate.status === 200) {
                window.open(`${baseURL + certificate.data[0].url}`, '_blank');
            }
        } catch (error) {
            console.log(error)
            alert(error.response?.data?.error?.message)
        }

    }

    async function sendImport() {
        setLoadingImport(true)
        console.log({ formImport });
        if (!selectedEvent.current) {
            setLoadingImport(false)
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `Selecione um evento para iniciar a leitura` })
            return
        } else
            if (!readAt) {
                setLoadingImport(false)
                setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `Selecione uma data e hora para gerar as leituras` })
                return
            }
        formImport.append('event', selectedEvent.current);
        formImport.append('readAt', readAt);
        console.log({ formImport });
        try {
            const importFrequency = await api.post('/frequency/import', formImport);
            setStateAlert({ ...stateAlert, status: true, type: 'success', msg: `Importação enviada com sucesso` });
            formImport.delete('file')
            setResultImport(importFrequency);
            setLoadingImport(false)
            setimportmodal(false)
            setimportmodal2(true)
        } catch (error) {
            console.log(error)
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: error.message || error.response.data.error.message })
            formImport.delete('file')
            setLoadingImport(false)
        }
    };


    return (
        <Box sx={{ width: '100%', height: '100vh' }}>
            <CssBaseline />
            <Snackbar
                open={stateAlert.status}
                autoHideDuration={2500}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setStateAlert({ status: false, type: 'success', msg: '' })} >
                <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                    {stateAlert.msg}
                </Alert>
            </Snackbar>
            <Grid container spacing={4} p={3} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth >
                        <InputLabel id="select-event">Selecionar Evento:</InputLabel>
                        <Select
                            labelId="select-event"
                            id="select-event-frequency"
                            value={eventReader}
                            onChange={(event) => {
                                setEventReader(event.target.value)
                                console.log(events)
                                selectedEvent.current = event.target.value
                                subscriberPrev.current = null
                                handleFrequencies()
                            }
                            }
                            label="Selecionar Evento"
                        >
                            <MenuItem value="">
                                <em>Nenhum</em>
                            </MenuItem>
                            {events.map(evento =>
                                <MenuItem key={evento.id} value={evento.id}>{evento.attributes.name}</MenuItem>
                            )}

                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container p={2} mx={'auto'} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={4} md={6}>
                    <FormControl fullWidth >
                        <TextField
                            margin="dense"
                            fullWidth
                            autoFocus
                            autoComplete="false"
                            ref={inputReader}
                            label="Código inscrito"
                            type="number"
                            value={subcriberCode}
                            onChange={(e) => {
                                inputAddSubscriber(e);
                                setSubscriberCode(e.target.value)
                            }
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4} md={1}>
                    <Button variant='outlined' onClick={() => setCamera(!camera)}>
                        {camera ? "Parar Câmera" : "Usar Câmera"}
                    </Button>
                </Grid>
                <Grid item xs={4} md={1}>
                    <Button variant='outlined' color='success' component="label" fullWidth disabled={loadingImport} onClick={() => setimportmodal(true)}>
                        Importar
                    </Button>
                </Grid>
            </Grid>
            {offlineMode && <p>Você está offline. Os dados serão armazenados localmente.</p>}
            {offlineMode && <CircularProgress />}
            <Grid px={5}>
                {camera && <ScannerCode onDetected={onDetected} />}
            </Grid>
            <Grid container p={3}>
                <Box sx={{ width: '100%', overflow: 'hidden', }} >
                    {offlineQueue.length > 0 ?
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columnsQueue.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            align={column.align}
                                            style={{ minWidth: column.width }}
                                        >
                                            {column.Header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offlineQueue.map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            {columnsQueue.map((column) => {
                                                const value = column.accessor == 'readAt' ? format(parseISO(row[column.accessor]), 'dd/MM/yy HH:mm') : row[column.accessor]
                                                if (value) return (
                                                    <TableCell key={column.accessor}>
                                                        {value}
                                                    </TableCell>
                                                )
                                            }
                                            )}
                                        </TableRow>
                                    )
                                })
                                }
                            </TableBody>
                        </Table>
                        :
                        null
                    }
                </Box>
            </Grid>

            <Modal
                open={modalimport}
                onClose={() => setimportmodal(false)}
                closeAfterTransition
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    boxShadow: 24,
                    p: 4, width: '40%',
                    height: '60%',
                    backgroundColor: '#eee',
                }}>
                    <Grid container spacing={4} p={3} justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth >
                                <InputLabel id="select-event">Selecionar Evento:</InputLabel>
                                <Select
                                    labelId="select-event"
                                    id="select-event-frequency"
                                    value={eventReader}
                                    onChange={(event) => {
                                        setEventReader(event.target.value)
                                        selectedEvent.current = event.target.value
                                        subscriberPrev.current = null
                                        console.log(events, selectedEvent.current)
                                        handleFrequencies()
                                    }
                                    }
                                    label="Selecionar Evento"
                                >
                                    <MenuItem value="">
                                        <em>Nenhum</em>
                                    </MenuItem>
                                    {events.map(evento =>
                                        <MenuItem key={evento.id} value={evento.id}>{evento.attributes.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xa={12} md={12}>
                            <FormControl fullWidth >
                                <InputLabel id="select-event">Selecionar Referência:</InputLabel>
                                <Select
                                    labelId="select-refer"
                                    id="select-refer-frequency"
                                    onChange={(event) => {
                                        console.log(event.target.value)
                                        setReadAt(event.target.value)
                                        console.log(readAt)
                                    }
                                    }
                                    label="Selecionar Referência"
                                >
                                    <MenuItem value="">
                                        <em>Nenhum</em>
                                    </MenuItem>
                                    {events.map(evento => {
                                        if (evento.id == selectedEvent.current && evento.attributes.collectors && evento.attributes.collectors.data.length > 0) {
                                            return evento.attributes.collectors.data.map(collect =>
                                                <MenuItem key={collect.attributes.collect_start} value={collect.attributes.collect_start}>
                                                    {`Inicia: ${format(parseISO(collect.attributes.collect_start), "dd/MM/yy HH:mm")} 
                                                    Encerra: ${format(parseISO(collect.attributes.collect_finish), "dd/MM/yy HH:mm")} 
                                                    Crédito: ${collect.attributes.credit}h`}
                                                </MenuItem>
                                            )
                                        }
                                    }
                                        //
                                    )}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button variant='contained' color='warning' onClick={(e) => e.target.value = null} component="label" fullWidth disabled={loadingImport} >
                                Selecionar Arquivo
                                <input
                                    hidden
                                    name="import"
                                    type={'file'}
                                    onChange={(e) => {
                                        console.log(e.target.value)
                                        handleImport(e)
                                    }}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    label="import"
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={12} spacing={2} py={10} md={12} fullWidth sx={{ justifyContent: 'center' }}>
                            <Button variant="contained" fullWidth onClick={() => sendImport()} disabled={loadingImport}>Importar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={modalimport2}
                onClose={() => setimportmodal2(false)}
                closeAfterTransition
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: 24,
                    p: 1,
                    overflowY: 'auto',
                    width: '70%',
                    height: '90%',
                    backgroundColor: '#eee',
                }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>CPF</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Celular</TableCell>
                                    <TableCell>Ação</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {resultImport.data !== undefined && resultImport.data.length > 0 && resultImport.data.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell width='16%'>
                                            {row.status == 'rejected' ? <>
                                                <ErrorIcon color="error" />
                                                <Typography fontSize={12} flexWrap>{row.reason?.msg}</Typography>
                                            </> : <CheckCircleIcon color="success" />}
                                        </TableCell>
                                        {console.log(row.reason?.subscriber || row.value?.subscriber)}
                                        {row.reason?.subscriber || row.value?.subscriber ?
                                            <>
                                                <TableCell>{row.reason?.subscriber?.name || row.value?.subscriber?.name}</TableCell>
                                                <TableCell>{row.reason?.subscriber?.document || row.value?.subscriber?.document}</TableCell>
                                                <TableCell>{row.reason?.subscriber?.email || row.value?.subscriber?.email}</TableCell>
                                                <TableCell>{row.reason?.subscriber?.whatsapp || row.value?.subscriber?.whatsapp}</TableCell>
                                            </>
                                            : <>
                                                <TableCell>{row.reason?.id || row.value?.id}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </>}
                                        <TableCell>
                                            {row.status == 'rejected' && (
                                                <IconButton color="error" onClick={() => alert(row.reason?.msg)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            )}
                                            {row.status == 'fulfilled' && (
                                                <IconButton color="primary" onClick={() => generateCert({ event: row.value?.event.name || row.reason?.event.name, document: row.value?.subscriber?.document || row.reason?.subscriber?.document })}>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            )}
                                            {row.reason?.event == 'fulfilled' && (

                                                <IconButton color="primary" onClick={() => generateCert({ event: row.value?.event.name || row.reason?.event.name, document: row.value?.subscriber?.document || row.reason?.subscriber?.document })}>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 5 }}>
                        <Button variant='contained' onClick={() => setimportmodal2(false)}>Ok</Button>
                    </Box>
                </Box>
            </Modal>
            <Grid container p={2} justifyContent="center" alignItems="center">
                <TableInfo title='Registro de Frequencia' variant="info" rows={frequencies} columns={columns} />
            </Grid>

            <Stack direction="row" alignItems="center" justifyContent={'flex-end'} spacing={2} mx={10}>
                <Pagination count={pagination.pageCount} defaultPage={1} color="primary" page={page.current}
                    onChange={(event, value) => {
                        page.current = value
                        handleFrequencies()
                    }} />
            </Stack>
        </Box>
    )

}