import React, {useContext, createContext} from 'react'

export const EditEventContext = createContext({});

export function EditEventProvider({children}) {
   const [activeStep, setActiveStep] = React.useState(0);
   const [statusForm, setStatusForm] = React.useState([]);
   const [eventDTO, setEventDTO] = React.useState([]);

   return <EditEventContext.Provider value={{ activeStep, setActiveStep, statusForm, setStatusForm, eventDTO, setEventDTO}}>{children}</EditEventContext.Provider>
}
export function useEditEvent() {
   const context = useContext(EditEventContext);
 
   return context;
 }