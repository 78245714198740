import React from 'react'
import { EditEventProvider } from '../../../contexts/editEvent.context'
import EditEvent from '../EditEvent'


export default function EditForm(){
    return(
        <EditEventProvider>
            <EditEvent/>
        </EditEventProvider>
    )
}