import React from "react"
import { Paper,Box,Typography, ButtonBase } from "@mui/material"

export default function CardInfo(props) {
    return (
        <Paper {...props} >
        <ButtonBase sx={{width:'100%',height: '95px',justifyContent: 'space-between', padding: 2}}>
           <Box sx={{alignSelf:'start', flexDirection:'row',textAlign:'left'}}>
                <Typography variant="subtitle2">{props.title}</Typography>
                <Typography variant="h6">{props.info}</Typography>
            </Box>
            <Box sx={{flexDirection: 'column', }}>
                {props.icon}
            </Box>
            </ButtonBase>
        </Paper>
    )
}