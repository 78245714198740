import { Routes } from './routes';
import { AuthProvider } from './contexts/auth.context';
import { NavigationProvider } from './contexts/navigation.context';
import { EventProvider } from './contexts/event.context';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <NavigationProvider>
            <EventProvider>
              <Routes />
            </EventProvider>
        </NavigationProvider>
      </AuthProvider>
    </div>
  );
}

export default App;