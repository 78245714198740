import React, { useEffect } from 'react';
import { Typography, Box, Grid, Button,TextField ,CircularProgress, Dialog, DialogContent, DialogActions,DialogTitle, Snackbar} from "@mui/material";
import { green } from '@mui/material/colors';
import MuiAlert from '@mui/material/Alert';
import TableInfo from '../../components/TableInfo';
import Add from '@mui/icons-material/Add'

import * as yup from 'yup';
import { useFormik } from 'formik';

import api, {baseURL} from '../../services/api';

export default function Certidoes(){
    const [loadingFile, setLoadingFile] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [listFiles, setListFiles] = React.useState([])
    const [sendindDocument, setSendingDocument] = React.useState(false)
    const [stateAlert, setStateAlert] = React.useState({status:false, type: 'success', msg:''});
    const [loading, setLoading] = React.useState(true)

    const columns = [
        { Header: "Arquivo", accessor: "title", width: "100%" },
        { Header: "Files", accessor: "actions", width: "90%" },
    ];
    
    const validationDocument = yup.object({
        title: yup
        .string('Insira o nome do Arquivo')
        .required('Campo necessário'),
        file: yup
            .mixed('Envie o template do certificado')
            .required('Campo necessário'),
    })

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    async function handleCertificate(event){
        const files = event.target.files
        const formData = new FormData()
        formData.append('files', files[0])
        setLoadingFile(true)
        try{
            const doc = await api.post('/upload',formData)
            formDocument.setFieldValue(event.target.name, doc.data[0].id)
            setStateAlert({...stateAlert, status:true, type: 'success', msg: `${event.target.name} enviado com sucesso`})
            setLoadingFile(false)
        }catch(error){  
            setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Erro ao enviar!'})
            setLoadingFile(false)
        }
    };

    const formDocument = useFormik({
        initialValues:{title:'',file:''},
        validateOnMount:true,
        validationSchema: validationDocument,
        enableReinitialize: true,
        onSubmit: (values) => {
            sendData(values)
        },
    });

    async function sendData(values){
        try{
            setSendingDocument(true)
            const documentSaved = await api.post('/documents', { data: values})
            if(documentSaved.status === 200){
                setStateAlert({...stateAlert, status:true,type:'success',msg:'Documento salvo com sucesso'})
                setSendingDocument(false)
                await handleFiles()
                formDocument.handleReset()
                setDialogOpen(false)
            }
        }catch(error){
            setStateAlert({...stateAlert, status:true,type:'error',msg:`${error.message}`})
            setSendingDocument(false)
        }
    }

    async function handleDelete({id}){
        try{
            const deleteFile = await api.delete('/documents/'+id)
            if(deleteFile.status === 200){
                setStateAlert({...stateAlert, status:true,type:'success',msg:`Documento excluído com sucesso`})
                await handleFiles()
            }
        }catch(error){
            console.log(error)
            setStateAlert({...stateAlert, status:true,type:'error',msg:`${error.message}`})
        }
    }
    
    async function handleView({id}){
        try{
            const [files] = listFiles.filter(file => id == file.id)
            window.open(`${baseURL+files.attributes.file.data.attributes.url}`, '_blank');
        }catch(error){
            console.log(error)
            setStateAlert({...stateAlert, status:true,type:'error',msg:`${error.message}`})
        }
    }

    async function handleFiles(){
        try{
            const files = await api.get('/documents?populate=*')
            if(files.status === 200){
                setListFiles(files.data.data)
                setLoading(false)
            }
        }catch(error){  
            setStateAlert({...stateAlert, status:true,type:'error',msg:`${error.message}`})
            setLoading(false)
        }
    }

    useEffect(()=> { handleFiles() },[])

    return (
        <Box sx={{width:'100%', height: '100vh'}} p={5}>
            <Grid container px={12} sx={{justifyContent: 'space-between'}}>
                <Grid item xs={3}>
                    <Typography variant='h4'>Cadastro de Certidões</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" onClick={() => setDialogOpen(true)} startIcon={<Add />}>Adicionar Documento</Button>
                </Grid>
            </Grid>
            <Snackbar
                open={stateAlert.status}
                autoHideDuration={2500}
                anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
                onClose={() => setStateAlert({status: false, type: 'success', msg:'' } )} >
                    <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                        {stateAlert.msg}
                    </Alert>
            </Snackbar>
            <Dialog
                open={dialogOpen}
                maxWidth={'lg'}
                aria-describedby="edit-dialog" >
                <DialogTitle>{"Adicionar Certidão"}</DialogTitle>
                <form onSubmit={formDocument.handleSubmit}>
                <DialogContent>
                    <Grid container spacing={2} columns={12} sx={{justifyContent: 'center'}}>
                        <Grid item xs={12}>
                            <TextField sx={{width: '100%'}} 
                                label="Titulo da certidão"  
                                id='title'
                                name="title" 
                                variant="outlined"
                                value={formDocument.values.title} 
                                onChange={formDocument.handleChange}
                                error={formDocument.touched.title && Boolean(formDocument.errors.title)}
                                helperText={formDocument.touched.title && formDocument.errors.title}
                                placeholder="Titulo da certidão"
                                />
                        </Grid>
                        <Grid item xs={10}>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button variant="contained" component="label" fullWidth  disabled={loadingFile} >
                                    Adicionar Arquivo
                                    <input
                                        hidden
                                        name="file" 
                                        type={'file'}   
                                        accept={".png, .jpg, .jpeg, .pdf, .docx, .doc"}
                                        label="Adicionar Arquivo" 
                                        onChange={(e) => handleCertificate(e)} 
                                        error={formDocument.touched.file && Boolean(formDocument.errors.file)}
                                        helperText={formDocument.touched.file && formDocument.errors.file}
                                        sx={{width: '100%'}}
                                        variant="outlined" 
                                    />
                                    </Button>
                                    {loadingFile && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                            </Box>
                            <Typography variant='caption'>{!!formDocument.values.file ? 'Documento Enviado' : ''}</Typography>
                            <Typography variant='caption' color={"#FF0000"}>{formDocument.errors.file}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => { setDialogOpen(false); formDocument.handleReset() }} color="error">Cancelar</Button>
                    <Box sx={{ m: 1, position: 'relative' }}>
                                <Button variant="contained" color='success' type={'submit'} fullWidth disabled={sendindDocument}>Salvar</Button>
                                    {sendindDocument && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                            </Box>
                </DialogActions>
                </form>
            </Dialog>
            <Grid container p={3}>
            {loading ?
                    <Box sx={{ width:'100%', height: '10vh',display: 'flex', justifyContent:'center' }}>
                        <CircularProgress sx={{ alignSelf:'center'}} size={50}/>
                    </Box> 
            :
                <TableInfo title='Certidões' variant="secondary" deactive={handleDelete} view={handleView} rows={listFiles} columns={columns} />
            }
            </Grid>
        </Box>
    )
}