import React from 'react'

export const FormContext = React.createContext({
   activeStep: 0,
   setActiveStep: () => {},
   statusForm: [],
   setStatusForm: () => {}
});

export function FormContextProvider({children}) {
   const [activeStep, setActiveStep] = React.useState(0);
   const [statusForm, setStatusForm] = React.useState([])


   return <FormContext.Provider value={{activeStep,setActiveStep, statusForm, setStatusForm}}>{children}</FormContext.Provider>
 }