export const TOKEN_KEY = "@EVAPI:token";
export const PEM_SCREENS = "@EVAPI:permissions"

export const isAuthenticated = () =>  { return !localStorage.getItem(TOKEN_KEY).trim()}
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getPermissions = () => sessionStorage.getItem(PEM_SCREENS)
export const setPermissions = token => sessionStorage.setItem(PEM_SCREENS,token)

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
};
