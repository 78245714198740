import React from 'react'
import { AddEventProvider } from '../../../contexts/addEvent.context'
import AddEvent from '../AddEvent'


export default function EventForm(){
    return(
        <AddEventProvider>
            <AddEvent/>
        </AddEventProvider>
    )
}