import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import { FormContextProvider  } from '../../../contexts/FormContext';
import { EventProvider  } from '../../../contexts/EventContext';
import { CompanyContextProvider  } from '../../../contexts/CompanyContext';
import { SubscribersProvider } from '../../../contexts/SubscribersContext';
import { OrderContextProvider } from '../../../contexts/OrderContext'
import Payment from './Payment';


export default function FormSubscriber() {
  return (
    <FormContextProvider>
      <EventProvider>
      <OrderContextProvider>
        <CompanyContextProvider>
          <SubscribersProvider>
            <CssBaseline />
            <Payment/>
          </SubscribersProvider>
        </CompanyContextProvider>
        </OrderContextProvider>
      </EventProvider>
     </FormContextProvider>
  );
}