import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useAddEvent } from '../../contexts/addEvent.context'
import { useNavigation } from '../../contexts/navigation.context'
import { useEvent } from '../../contexts/event.context';

import { Typography, Stack, Box, Container,IconButton,
         Step, Stepper, StepLabel, Button} from "@mui/material"
import ArrowBack from '@mui/icons-material/ArrowBack'

import Information from './StepAddEvent/info';
import Apresentation from './StepAddEvent/apresentation';
import Subscribers from './StepAddEvent/subscribers';
import Schedule from './StepAddEvent/schedule';
import Accomodation from './StepAddEvent/accommodation';
import Location from './StepAddEvent/location';

export default function AddEvent(){
    const { activeStep } = useAddEvent()
    const { getEvents } = useEvent();  
    const navigation = useNavigation();
    const navigate = useNavigate();

    const steps = ['Informações', 'Apresentação', 'Das Inscrições','Local', 'Hospedagem', 'Programação'];
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Information/>;
      case 1:
        return <Apresentation />;
      case 2: 
        return <Subscribers/>
      case 3:
        return <Location />;
      case 4:
        return <Accomodation />;
      case 5:
        return <Schedule/>;
      default:
        throw new Error('Unknown step');
    }
  }
    useEffect(()=> {
        console.log(activeStep)
    },[activeStep])

    function handleBack (){
        getEvents()
        navigation.nextPage('/eventos')
        navigate(-1)
    };
    
    
    return (
        <Container sx={{pt: 4}}>
        <Stack direction={'row'} py={2} sx={{alignItems:'center'}}>
        <IconButton onClick={handleBack}>
            <ArrowBack/>
        </IconButton>
        <Typography variant={'caption'}>Voltar</Typography>
        </Stack>
        <Stack direction={'column'} spacing={2}>
            <Box>
                <Typography variant={'h5'}>Adicionar Novo Evento</Typography>
            </Box>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" textAlign={'center'} gutterBottom>
                    Evento cadastrado com sucesso
                  </Typography>
                  <Button variant="contained" onClick={()=>{ handleBack()}} style={{width: '30%', alignSelf: 'center'}}>
                    Acessar evento
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                </React.Fragment>
              )}
            </React.Fragment>
        </Stack>
        </Container>
    )
}