import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Box, Grid, Button, Typography} from '@mui/material'
import { useEditEvent } from '../../../contexts/editEvent.context'
import api from '../../../services/api'
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import 'react-quill/dist/quill.snow.css';


const Accommodation = () => {
    const [value, setValue] = useState('');
    const quillRef = useRef();
    const {eventDTO, activeStep, setActiveStep, setEventDTO} = useEditEvent()

    function handleInformation() {
        setEventDTO({...eventDTO, information_accommodation: value})
        console.log(eventDTO)
        setActiveStep(activeStep + 1)
    }
    async function handleUpdate() {
      try{
          const newEvent = await api.put('/events/'+eventDTO.id,{ data: {...eventDTO, information_accommodation: value} })
          if(newEvent.status === 200){
              setActiveStep(6)
          }
      }catch(error){
          alert(`erro: ${error.message}`)
      }
  }
    useEffect(()=> {
        if(quillRef.current){
            Quill.register('modules/imageUploader', ImageUploader);
            setValue(eventDTO.information_accommodation)
        }
       return () => {
            quillRef.current = null
       }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const modules = useMemo(() => ({
        toolbar: {
          container: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', "strike"],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['image', "link",],
            [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
          ],
          imageUploader: {
            upload: (file) => {
              return
            }
          }
        },
      }), [])

    return (
        <Box pb={2}>
            <Grid container spacing={2} columns={12} sx={{mb: 5}}>
            <Grid item xs={12}>
                <Typography variant='h5'>Informações da Hospedagem:</Typography>
              </Grid>
                <Grid item xs={12}>
                    <ReactQuill ref={quillRef} value={value} style={{height : "450px"}} modules={modules} 
                        onChange={setValue} theme='snow'  />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                 variant="contained"
                 onClick={ () =>  setActiveStep(activeStep - 1)}
                 sx={{ mt: 3, ml: 1 }}
                 color='warning'
                 >
                  Voltar
              </Button>
                <Button
                 variant="contained"
                 onClick={() => handleInformation()}
                 sx={{ mt: 3, ml: 1 }}>
                  Próximo
                </Button>
                <Button
                 variant="contained"
                 color='success'
                 onClick={() => handleUpdate()}
                 sx={{ mt: 3, ml: 1 }}>
                  Atualizar Evento
                </Button>
            </Box>
       </Box>
    )
}
export default Accommodation;
