import React, { useEffect } from 'react'
import { useNavigate, useParams} from "react-router-dom";
import { useEditEvent } from '../../contexts/editEvent.context'
import { useNavigation } from '../../contexts/navigation.context'
import { useEvent } from '../../contexts/event.context';
import api from '../../services/api'

import { Typography, Stack, Box, Container,IconButton,
         Step, Stepper, StepLabel, Button, CircularProgress} from "@mui/material"
import ArrowBack from '@mui/icons-material/ArrowBack'

import Information from './StepEditEvent/info';
import Apresentation from './StepEditEvent/apresentation';
import Subscribers from './StepEditEvent/subscribers';
import Schedule from './StepEditEvent/schedule';
import Accomodation from './StepEditEvent/accommodation';
import Location from './StepEditEvent/location';

export default function EditEvent(){
    const { activeStep, setEventDTO } = useEditEvent()
    const { getEvents } = useEvent();  
    const [loaded, setLoaded] = React.useState(false);
    let { eventId } = useParams();
    const navigation = useNavigation();
    const navigate = useNavigate();

    const steps = ['Informações', 'Apresentação', 'Das Inscrições','Local', 'Hospedagem', 'Programação'];
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Information/>;
      case 1:
        return <Apresentation />;
      case 2: 
        return <Subscribers/>
      case 3:
        return <Location />;
      case 4:
        return <Accomodation />;
      case 5:
        return <Schedule/>;
      default:
        throw new Error('Unknown step');
    }
  }
    useEffect(() => {
        console.log(eventId)
        if(!loaded){ 
            handleEditEvent(eventId)
        }
        return () => {
          setLoaded(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function handleEditEvent(eventId){
      try{
        const event = await api.get('/events/'+eventId)
        if(event.status === 200) {
            setLoaded(true)
            setEventDTO({...event.data})
          }
      }catch(error){
          setLoaded(false)
          alert(error)
      }
    }
    function handleBack (){
        getEvents()
        navigation.nextPage('/eventos')
        navigate(-1)
    };
    
    
    return (
        <Container sx={{pt: 4}}>
        <Stack direction={'row'} py={2} sx={{alignItems:'center'}}>
        <IconButton onClick={handleBack}>
            <ArrowBack/>
        </IconButton>
        <Typography variant={'caption'}>Voltar</Typography>
        </Stack>
        {loaded ?
          <Stack direction={'column'} spacing={2}>
            <Box>
                <Typography variant={'h5'}>Editar Evento</Typography>
            </Box>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" textAlign={'center'} gutterBottom>
                    Evento editado com sucesso
                  </Typography>
                  <Button variant="contained" onClick={()=>{ handleBack()}} style={{width: '30%', alignSelf: 'center'}}>
                    Acessar evento
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                </React.Fragment>
              )}
            </React.Fragment>
          </Stack>
        : <CircularProgress/>}
        </Container>
    )
}