import React, { useRef,useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import api from '../../../services/api'
import { useEditEvent } from '../../../contexts/editEvent.context'
import { useNavigation } from '../../../contexts/navigation.context'

import { Box,Button, Grid, TextField, 
        FormControlLabel,FormGroup, Typography,
        Switch, Autocomplete, IconButton, ListItemIcon,
        ListItemButton, ListItemText, List, ListItem,
        Snackbar,CircularProgress
    } from '@mui/material'
import { green } from '@mui/material/colors';
import MuiAlert from '@mui/material/Alert'
import  Add  from '@mui/icons-material/Add'
import  DeleteIcon  from '@mui/icons-material/Delete'
import * as yup from 'yup';
import { useFormik } from 'formik';
import IntlCurrencyInput from "react-intl-currency-input"
import { parseISO, format, isEqual, areIntervalsOverlapping, addDays } from 'date-fns';

const Information = () => {
    const pricechange = useRef(0);
    const typechange = useRef();
    const collect_start= useRef()
    const collect_finish = useRef()
    const credit = useRef(0)
    const { activeStep, setActiveStep, setEventDTO, eventDTO } = useEditEvent()
    const [insertTypes, setInsertTypes] = useState([])
    const [insertCollectors, setInsertCollectors] = useState([])
    const navigate = useNavigate();
    const navigation = useNavigation();
    const [loadingCertificate, setLoadingCertificate] = React.useState(false);
    const [loadingImage, setLoadingImage] = React.useState(false);
    const [stateAlert, setStateAlert] = React.useState({status:false, type: 'success', msg:''});

    const validationInformation = yup.object({
        name: yup
            .string('Insira o nome do Evento')
            .required('Campo necessário'),
        isActive: yup
            .boolean()
            .required('Campo necessário'),
        start_subscription: yup
            .date()
            .required('Campo necessário'),
        end_subscription: yup
            .date()
            .min(yup.ref('start_subscription'),'A data deve ser maior que a data de inicio das incrições')
            .max(yup.ref('date_initial'), 'A data deve ser menor que o inicio do evento')
            .required('Campo necessário'),
        max_subscribers: yup
            .number('Campo deve ser do tipo numero')
            .positive('Deve ser um numero maior que zero')
            .typeError('Somente números')
            .required('Campo necessário'),
        certificate: yup
            .mixed('Envie o template do certificado')
            .required('Campo necessário'),
        plans: yup
            .array()
            .min(1,'Tipos de Inscrições Necessárias')
            .required('Tipos de insrição necessário'),
        collectors: yup
            .array(),
        date_initial: yup
            .date()
            .typeError('Data inválida')
            .min(yup.ref('end_subscription'),'A data deve ser maior que a data de inicio das incrições')
            .required('Campo necessário'),
        date_finish: yup
            .date()
            .typeError('Data inválida')
            .min(yup.ref('date_initial'),'A data deve ser maior que a data de inicio do evento')
            .required('Campo necessário'),
        thumbnail: yup
            .mixed('Envie a imagem do evento')
            .required('Campo necessário'),
        message_confirmation: yup
            .string('Insira mensagem de confirmação de inscrição')
            .required('Campo necessário'),
        city: yup
            .string('Insira a cidade do evento')
            .required('Campo necessário'),
        uf: yup
            .string('Insira a UF ')
            .required('Campo necessário'),
    });

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
          number: {
            BRL: {
              style: "currency",
              currency: "BRL",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          },
        },
    };
  
    useEffect(()=>{
        let loaded = false
        console.log(eventDTO !== null)
        console.log(formik.values)
        if(!loaded && eventDTO !== null){ 
            handlePlans()
            handleCollectors()
            loaded = true
        }
        return () => {
            formik.handleReset()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function handlePlans(){
        let types = eventDTO.plans
        let ids = []
        if(Object.keys(types).length > 0){
            for await(const plan of types){
                let id = plan.id === undefined ? plan : plan.id
                ids.push(id)
                if(insertTypes.find(plano => plano.id === id)){
                    return
                }else{
                    const loadPlans = await api.get('/plans/'+id)
                        if(loadPlans.status === 200){
                            setInsertTypes(prev => [...prev, loadPlans.data.data])
                        }
                }
            }
            formik.setFieldValue('plans', ids)
            setEventDTO({...eventDTO, plans: ids})
                
        }
    }
    async function handleCollectors(){
        let collects = eventDTO.collectors
        let ids = []
        if(Object.keys(collects).length > 0){
            for await(const collector of collects){
                let id = collector.id === undefined ? collector : collector.id
                ids.push(id)
                if(insertCollectors.find(col => col.id === id)){
                    return
                }else{
                    const loadCollectors = await api.get('/collectors/'+id)
                        if(loadCollectors.status === 200){
                            setInsertCollectors(prev => [...prev, loadCollectors.data.data])
                        }
                }
            }
            formik.setFieldValue('collectors', ids)
            setEventDTO({...eventDTO, collectors: ids})
                
        }
    }

    const formik = useFormik({
        initialValues: eventDTO,
        validateOnMount:true,
        validationSchema: validationInformation,
        enableReinitialize:false,
        onSubmit: (values) => {
            console.log('values formik',values)
            setEventDTO({...eventDTO, ...values})
            setActiveStep(activeStep + 1)
        },
    });
    function handleBack (){
        navigation.nextPage("/eventos")
        navigate(-1)
    };

    async function handelDeleteType(id){
        try{
            const plan = await api.delete("/plans/"+id)
            if(plan.status === 200){
                setStateAlert({...stateAlert, status:true, type: 'success', msg: 'Tipo de inscrição excluído com sucesso!'})
                const newPlan = insertTypes.filter(plans => plans.id !== id )
                if(newPlan.length > 0){
                    let ids = []
                    for await (const type of newPlan){
                        ids.push(type.id)
                    }
                    setEventDTO({...eventDTO, plans: ids})
                    setInsertTypes([...newPlan])
                    formik.setFieldValue('plans',  ids)
                }else{
                    setInsertTypes([])
                    formik.setFieldValue('plans',[])
                }
            }
        }catch(error){
                let msg = error.response.data.error.message || 'Erro ao excluir tipo de inscrição!'
                setStateAlert({...stateAlert, status:true, type: 'error', msg})
        }
    }
    async function handelDeleteCollect(id){
        try{
            const collect = await api.delete("/collectors/"+id)
            if(collect.status === 200){
                setStateAlert({...stateAlert, status:true, type: 'success', msg: 'Períodos de referência para leitura excluído com sucesso!'})
                const newCollect = insertCollectors.filter(coll => coll.id !== id )
                if(newCollect.length > 0){
                    let ids = []
                    for await (const collector of newCollect){
                        ids.push(collector.id)
                    }
                    setEventDTO({...eventDTO, collectors: ids})
                    setInsertCollectors([...newCollect])
                    formik.setFieldValue('collectors',  ids )
                }else{
                    setInsertCollectors([])
                    formik.setFieldValue('collectors',[])
                }
            }
        }catch(error){
                console.log(error)
                setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Erro ao excluir período de referência para leitura!'})
        }
    }
    async function handleInformation() {
        try{
            const newEvent = await api.put('/events/'+eventDTO.id,{ data: formik.values})
            if(newEvent.status === 200){
                setActiveStep(6)
            }
        }catch(error){
            setStateAlert({...stateAlert, status:true, type: 'error', msg: `${error.message}`})
        }
    }

    async function addTypes(){
        let plan = eventDTO.plans
        const types = {price: pricechange.current, type: typechange.current}
        console.log(types)
        try{
            if(types.price  === null || types.type == null) {
                setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Tipo ou Valor da inscrição é requerido!'})
                return 
            }
            
            if(Object.keys(plan[0]).length === 0 && plan.length > 0 ){
                if(insertTypes.find(types => types.attributes.type === typechange.current )){
                    setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Tipo de inscrição já adicionada!'})
                    return 
                }
            }
            const plans = await api.post("/plans",{ data: types })
            if(plans.status === 200){
                console.log(plans.data.data)
                setStateAlert({...stateAlert, status:true, type: 'success', msg: 'Tipo de inscrição adicionada com sucesso!'})
                setInsertTypes([...insertTypes, plans.data.data])
                typechange.current = null
                pricechange.current = 0
                formik.setFieldValue('plans', [...formik.values.plans, plans.data.data.id])
                setEventDTO({...eventDTO, plans: formik.values.plans})
                console.log(formik.values)
            }
        }catch(error){
            console.log(error)
             setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Erro ao cadastrar tipo de inscrição!'})
        }
    };

    async function addCollectors(){
        const collectors = {"collect_start": collect_start.current,"collect_finish": collect_finish.current, "credit": credit.current}
        console.log(collectors)
        console.log({start: parseISO(formik.values.date_initial), end: addDays(parseISO(formik.values.date_finish),1)},
        {start: parseISO(collect_start.current), end: parseISO(collect_finish.current)})
        try{
            if(collectors.collect_start  === null || collectors.collect_finish == null || collectors.credit <= 0 ) {
                setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Períodos de referência para leitura é requerido!'})
                return 
            }else if(insertCollectors.filter(collect => areIntervalsOverlapping(
                                {start: parseISO(collect.attributes.collect_start), end: parseISO(collect.attributes.collect_finish)},
                                {start: parseISO(collect_start.current), end: parseISO(collect_finish.current)}
                )).length > 0 ){

                setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Períodos de coleta já adicionado!'})
                return 
            }else if(!areIntervalsOverlapping(
                        {start: parseISO(formik.values.date_initial), end: addDays(parseISO(formik.values.date_finish),1)},
                        {start: parseISO(collect_start.current), end: parseISO(collect_finish.current)})
                    ){
                        console.log({start: parseISO(formik.values.date_initial), end: addDays(parseISO(formik.values.date_finish),1)},
                        {start: parseISO(collect_start.current), end: parseISO(collect_finish.current)})
                    setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Períodos de coleta deve estar dentro do intervalo de datas do evento!'})
                return 
            }
            const collectorsEvent = await api.post("/collectors",{ data: collectors })
            if(collectorsEvent.status === 200){
                collect_start.current = ""
                credit.current = ""
                collect_finish.current = ""
                setStateAlert({...stateAlert, status:true, type: 'success', msg: 'Períodos de referência para leitura adicionada com sucesso!'})
                setInsertCollectors([...insertCollectors, collectorsEvent.data.data])
                formik.values.collectors ==  undefined ? formik.setFieldValue('collectors', [collectorsEvent.data.data.id]) : formik.setFieldValue('collectors', [...formik.values.collectors, collectorsEvent.data.data.id])
                console.log(formik.values)
            }
        }catch(error){
            console.log(error)
             setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Erro ao cadastrar Períodos de referência para leitura!'})
        }
    };

    const listTypes = ['RPPS associado à AGIP','RPPS não associado','Órgãos Públicos (exceto Instituições Financeiras) ou Entes que não possuem RPPS',
                       'Outras Instituições e/ou Prestadores de Serviços','Patrocinador']

    async function handleCertificate(event,field){
        const files = event.target.files
        const formData = new FormData()
        formData.append('files', files[0])
        field === 'doc' ? setLoadingCertificate(true) : setLoadingImage(true)
            try{
                const doc = await api.post('/upload',formData)
                formik.setFieldValue(event.target.name, doc.data[0].id)
                setStateAlert({...stateAlert, status:true, type: 'success', msg: `${event.target.name} enviado com sucesso`})
                 field === 'doc' ? setLoadingCertificate(false) : setLoadingImage(false)
            }catch(error){  
                setStateAlert({...stateAlert, status:true, type: 'error', msg: 'Erro ao enviar!'})
                field === 'doc' ? setLoadingCertificate(false) : setLoadingImage(false)
            }
    };
                
    return (
        <>
        <Snackbar
            open={stateAlert.status}
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            onClose={() => setStateAlert({status: false, type: 'success', msg:'' } )} >
                <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                    {stateAlert.msg}
                </Alert>
        </Snackbar>
        <form onSubmit={formik.handleSubmit}>
        <Box pb={2}>
        <Grid container spacing={2} columns={12} sx={{mb: 5}}>
            <Grid item xs={6}>
                <TextField sx={{width: '100%'}} 
                    label="Nome do Evento"  
                    id='name'
                    focused
                    name="name" 
                    variant="outlined"
                    value={formik.values.name} 
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    placeholder="Nome do Evento"/>
            </Grid>
            <Grid item xs={3}>
                <TextField sx={{width: '100%'}} 
                    label="Máximo de Inscrições"  
                    type={'number'}
                    focused
                    name="max_subscribers" 
                    id="max_subscribers"
                    variant="outlined"
                    value={formik.values.max_subscribers} 
                    onChange={formik.handleChange}
                    error={formik.touched.max_subscribers && Boolean(formik.errors.max_subscribers)}
                    helperText={formik.touched.max_subscribers && formik.errors.max_subscribers}
                />
            </Grid>
                <Grid item xs={3}>
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Button variant="contained" component="label" fullWidth  disabled={loadingCertificate} >
                        Certificado
                        <input
                            hidden
                            name="certificate" 
                            type={'file'}   
                            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            label="Template do Certificado" 
                            onChange={(e) => handleCertificate(e,'doc')} 
                            error={formik.touched.certificate && Boolean(formik.errors.certificate)}
                            helperText={formik.touched.certificate && formik.errors.certificate}
                            sx={{width: '100%'}}
                            variant="outlined" 
                        />
                        </Button>
                        {loadingCertificate && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                            )}
                        </Box>
                        <Typography variant='caption'>{!!formik.values.certificate ? 'Certificado Cadastrado' : ''}</Typography>
                        <Typography variant='caption' color={"#FF0000"}>{formik.errors.certificate}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        type={"date"} 
                        id="start_subscription"
                        name="start_subscription"  
                        focused
                        sx={{width: '100%'}}
                        value={formik.values.start_subscription} 
                        label="Ínicio das inscrições"  
                        onChange={formik.handleChange}
                        error={formik.touched.start_subscription && Boolean(formik.errors.start_subscription)}
                        helperText={formik.touched.start_subscription && formik.errors.start_subscription}
                        variant="outlined"  />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                    type={"date"} 
                    name="end_subscription" 
                    id="end_subscription"
                    focused
                    sx={{width: '100%'}} 
                    value={formik.values.end_subscription} 
                    onChange={formik.handleChange}
                    error={formik.touched.end_subscription && Boolean(formik.errors.end_subscription)}
                    helperText={formik.touched.end_subscription && formik.errors.end_subscription}
                    label="Encerramento das inscrições" 
                    variant="outlined" />
                </Grid>
                <Grid item xs={3}>
                    <FormGroup>
                        <FormControlLabel 
                        name="isActive" 
                        focused
                        control={<Switch defaultChecked={formik.values.isActive} />} 
                        id="isActive"
                        onChange={formik.handleChange}
                        error={formik.touched.isActive && Boolean(formik.errors.isActive)}
                        helperText={formik.touched.isActive && formik.errors.isActive}
                        value={formik.values.isActive} 
                        label={"Evento Ativo"} />
                    </FormGroup>
                </Grid>
                <Grid item xs={3}>
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Button variant="contained" component="label" fullWidth  disabled={loadingImage}>
                        Imagem do Evento
                        <input
                            hidden
                            name="thumbnail" 
                            type={'file'}   
                            accept="image/*"
                            label="Thumbnail" 
                            onChange={(e) => handleCertificate(e,'img')} 
                            error={formik.touched.thumbnail && Boolean(formik.errors.thumbnail)}
                            helperText={formik.touched.thumbnail && formik.errors.thumbnail}
                            sx={{width: '100%'}}
                            variant="outlined" 
                        />
                        </Button>
                        {loadingImage && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                            )}
                            </Box>
                        <Typography variant='caption'>{!!formik.values.thumbnail ? 'Imagem Cadastrada' : ''}</Typography>
                        <Typography variant='caption' color={"#FF0000"}>{formik.errors.thumbnail}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        type={"date"} 
                        id="date_initial"
                        name="date_initial"  
                        sx={{width: '100%'}}
                        value={formik.values.date_initial} 
                        focused
                        label="Data Inicial do Evento"  
                        onChange={formik.handleChange}
                        error={formik.touched.date_initial && Boolean(formik.errors.date_initial)}
                        helperText={formik.touched.date_initial && formik.errors.date_initial}
                        variant="outlined"  />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        type={"date"} 
                        id="date_finish"
                        name="date_finish"  
                        sx={{width: '100%'}}
                        focused
                        value={formik.values.date_finish} 
                        label="Data Final do Evento"  
                        onChange={formik.handleChange}
                        error={formik.touched.date_finish && Boolean(formik.errors.date_finish)}
                        helperText={formik.touched.date_finish && formik.errors.date_finish}
                        variant="outlined"  />
                </Grid>
                <Grid item xs={4}>
                <TextField sx={{width: '100%'}} 
                    label="Cidade"  
                    focused
                    id='city'
                    name="city" 
                    variant="outlined"
                    value={formik.values.city} 
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    placeholder="Cidade onde acontecerá Evento"/>
            </Grid>
            <Grid item xs={2}>
                <TextField sx={{width: '100%'}} 
                    label="Estado (UF)"  
                    id='uf'
                    name="uf"
                    focused
                    variant="outlined"
                    inputProps={{ maxLength: 2, style: { textTransform: "uppercase" } }}
                    value={formik.values.uf} 
                    onChange={formik.handleChange}
                    error={formik.touched.uf && Boolean(formik.errors.uf)}
                    helperText={formik.touched.uf && formik.errors.uf}
                    placeholder="UF"/>
            </Grid>
                <Grid item xs={12}>
                <TextField sx={{width: '100%'}} 
                    label="Mensagem de confirmação enviada por email"  
                    id='message_confirmation'
                    name="message_confirmation" 
                    variant="outlined"
                    focused
                    value={formik.values.message_confirmation} 
                    onChange={formik.handleChange}
                    error={formik.touched.message_confirmation && Boolean(formik.errors.message_confirmation)}
                    helperText={formik.touched.message_confirmation && formik.errors.message_confirmation}
                    />
            </Grid>
            <Grid item xs={3} >
                <TextField sx={{width: '100%'}}
                    label="Data/Hora de inicio da coleta"
                    type={"datetime-local"} 
                    focused
                    name='collect_start'
                    onChange={(e) =>  collect_start.current = e.target.value}
                />
            </Grid>
            <Grid item xs={3} >
                <TextField sx={{width: '100%'}}
                    label="Data/Hora de final da coleta"
                    type={"datetime-local"} 
                    focused
                    name='collect_finish'
                    onChange={(e) =>  collect_finish.current = e.target.value}
                />
            </Grid>
            <Grid item xs={2} >
                <TextField sx={{width: '100%'}}
                    label="Crédito minimo em horas"
                    type="number" 
                    name='credit'
                    onChange={(e) =>  credit.current = e.target.value}
                    focused
                />
            </Grid>
            <Grid item xs={4}>
                <Button  fullWidth  variant="contained"  color='warning' style={{minHeight: '54px'}} onClick={addCollectors}  startIcon={<Add />} >
                    Adicionar referência de coleta
                </Button>
            </Grid>
            <Grid item xs={12} direction={'row'} >
                <Grid direction={'row'}  >
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {insertCollectors.map((collect,index) => {
                        const labelId = `checkbox-list-label-${index}`;
                        return (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="actions"  onClick={() => handelDeleteCollect(collect.id)}>
                                    <DeleteIcon color='error' />
                                </IconButton>
                            }
                            disablePadding
                            >
                            <ListItemButton role={undefined} dense >
                                <ListItemIcon/>
                                <ListItemText id={labelId} primary={`Inicia: ${format(parseISO(collect.attributes.collect_start), "dd/MM/yy HH:mm")} Encerra: ${format(parseISO(collect.attributes.collect_finish),"dd/MM/yy HH:mm")} Crédito: ${collect.attributes.credit}h`} />
                            </ListItemButton>
                        </ListItem>
                        );
                    })}
                    </List>
                    </Grid>
                </Grid> 
                <Grid item xs={12}>
                    <Typography variant='caption' color={"#FF0000"}>{formik.errors.collectors}</Typography>
                </Grid>
            <Grid item xs={6} >
                    <Autocomplete
                        disablePortal
                        id="combo-box-types"
                        fullWidth
                        freeSolo
                        placeholder="Tipo"
                        name="type" 
                        onChange={(event, newInputValue) => {
                              typechange.current = newInputValue
                        }}
                        onInputChange={(event, newInputValue) => {
                            typechange.current = newInputValue
                          }}
                        options={listTypes}
                        renderInput={(params) =>  
                            <TextField sx={{width: '100%'}} 
                                {...params}
                                label="Tipo"
                                ariant="outlined"
                            /> }
                        />
                    </Grid>
            <Grid item xs={4} >
                <IntlCurrencyInput 
                    currency="BRL" 
                    config={currencyConfig}
                    onChange={(event, value, maskedValue) => {
                        event.preventDefault();
                        pricechange.current = value
                        }
                    }
                    label="Valor"
                    sx={{width: '100%'}}
                    name="price" 
                    defaultValue={0}
                    variant="outlined"
                    placeholder="R$ 0,00"
                    component={TextField}
                />
            </Grid>
            <Grid item xs={2}>
                <Button  fullWidth  variant="contained" onClick={addTypes}  startIcon={<Add />} >
                    Adicionar tipo de inscrição
                </Button>
            </Grid>
            <Grid item xs={12} direction={'row'} >
                <Grid direction={'row'}  >
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {insertTypes.map((types,index) => {
                        const labelId = `checkbox-list-label-${index}`;
                        let type = types.type !== undefined ? types.type : types.attributes.type
                        let price = types.price !== undefined ? types.price : types.attributes.price
                        return (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="actions" onClick={() => handelDeleteType(types.id)}>
                                    <DeleteIcon color='error' />
                                </IconButton>
                            }
                            disablePadding
                            >
                            <ListItemButton role={undefined} dense>
                                <ListItemIcon/>
                                <ListItemText id={labelId} primary={`${type} - R$ ${price}`} />
                            </ListItemButton>
                        </ListItem>
                        );
                    })}
                    </List>
                    </Grid>
                </Grid> 
                <Typography variant='caption' color={"#FF0000"}>{formik.errors.plans}</Typography>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                 color='error'
                 variant="contained"
                 onClick={ () => handleBack() }
                 sx={{ mt: 3, ml: 1 }}>
                  Cancelar 
                </Button>
                <Button
                 variant="contained"
                 type={'submit'}
                 sx={{ mt: 3, ml: 1 }}>
                  Próximo
                </Button>
                <Button
                 variant="contained"
                 color='success'
                 onClick={() => handleInformation()}
                 sx={{ mt: 3, ml: 1 }}>
                  Atualizar Evento
                </Button>
                
            </Box>
        </Box>
    </form>
    </>
    )
}
export default Information;
