import React from 'react'

export const OrderContext = React.createContext({
    order: [{ 
        company: String,
        event: String,
        plan: String,
        quantity: Number,
        subscribers: Array,
        plan_describe: String,
        plan_price:String,
        payment_form: String,
        payment_url: String
    }],
    setOrder: () => {}
});

export function OrderContextProvider({children}) {
   const [order, setOrder] = React.useState([])


   return <OrderContext.Provider value={{order,setOrder}}>{children}</OrderContext.Provider>
 }