import React, { useLayoutEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { Dialog, DialogContent, DialogTitle, DialogActions, 
  Grid, FormControl, InputLabel, Select, MenuItem 
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import Payer from './Payer';
import PayerInform from './PayerInform';
import TypeSubcriber from './TypeSubscribers';
import AddSubscriber from './AddSubscriber';
import FinsihRegistration from './FinsihRegistration';

import api  from '../../../services/api'
import { useEvent } from '../../../contexts/EventContext';
import { useEvent as useSearchEvent}  from '../../../contexts/event.context'
import { FormContext  } from '../../../contexts/FormContext';
import { OrderContext  } from '../../../contexts/OrderContext';
import { useNavigation } from '../../../contexts/navigation.context' 

import { isWithinInterval, parseISO } from 'date-fns'

  
  const steps = ['Pagador', 'Informações do Pagador', 'Tipo de Inscrição','Adicionar Inscritos','Revisar Inscrição'];
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Payer/>;
      case 1:
        return <PayerInform />;
      case 2: 
        return <TypeSubcriber/>
      case 3:
        return <AddSubscriber />;
      case 4: 
        return <FinsihRegistration />;
      default:
        throw new Error('Unknown step');
    }
  }
  
  
  export default function Payment() {
    const [openEvent, setOpenEvent] = useState(true)
    const newEvents = useSearchEvent()
    const navigation = useNavigation()
    const { activeStep } = React.useContext(FormContext)
    let navigate = useNavigate()
    const { order, setOrder } = React.useContext(OrderContext)
    const { events, setEvents } = useEvent()
    const info = useRef(null)
    const [stateAlert, setStateAlert] = React.useState({status:false, type: 'success', msg:''});

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
   
    async function getEventById(id){
        try{
            const eventbyId = await api.get('/events/'+id)
            setEvents(...[eventbyId.data])
            setOrder({...order, event: id})
          let startDate = parseISO(eventbyId.data.start_subscription)
          let endDate = parseISO(eventbyId.data.end_subscription)
          let today = new Date();
          let result = isWithinInterval(today,{start: startDate, end: endDate})
          if(result === -1){
            setStateAlert({status:true, type: 'error', msg:'AS INSCRIÇÕES PARA O EVENTO AINDA NÃO ESTÃO ABERTAS!'})
            setTimeout(() => {
              handleBack()
            }, 4000)
            return
          }
          
        }catch(error){
            console.log(error)
        }
    }
    async function getInfoConfig(){
      try{
        const inform = await api.get('/configs/1')
        info.current = inform.data.data.attributes
        console.log(info.current)
      }catch(error){
        console.log(error)
      }
    }

    function handleBack(){
      navigation.setPage('/financeiro/')
      navigation.setLink(`/financeiro/`)
      navigate(`/financeiro/`)
    }
    function handlePayment(){
      window.open(order[0].payment_url)
    }

    useLayoutEffect(() => {
       getInfoConfig()
       return () => {
        setStateAlert({status:false, type: 'success', msg:''})
       }

    },[])

    return (
        <>
        <AppBar
          position="relative"
          color="default"
          elevation={0}
          sx={{
            position: 'relative',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
          }}
        >
           <Snackbar
                open={stateAlert.status}
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                onClose={() => setStateAlert({status: false, type: 'error', msg:'' } )} >
                    <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                        {stateAlert.msg}
                    </Alert>
            </Snackbar>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap textAlign={'center'}>
              { events.name || "Nome do Evento"}
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack direction={'row'} py={3} sx={{alignItems:'center'}}>
        <IconButton onClick={handleBack}>
            <ArrowBack color='error'/>
        </IconButton>
        <Typography variant={'body'} color='error'>Sair</Typography>
        </Stack>
        <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
              Cadastrar nova cobrança
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Cobrança gerada com sucesso!
                  </Typography>
                  <Button 
                    size='medium'
                    variant="contained"
                    type={'button'}
                    color='success'
                    onClick={() => handleBack()}
                    sx={{ mt: 3, mr: 1  }}>
                      Voltar para financeiro
                  </Button>
                  {order[0].payment_form == "Boleto"  &&
                  <Button 
                    size='medium'
                    variant="contained"
                    type={'button'}
                    color='primary'
                    onClick={() => handlePayment()}
                    sx={{ mt: 3, mr: 1  }}>
                     Acessar boleto
                  </Button>
                  }
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
          <Dialog
                open={openEvent}
                aria-describedby="edit-dialog" >
                <DialogTitle>Selecione o evento para iniciar a cobrança:</DialogTitle>
                <DialogContent>
                  <Grid container spacing={6} py={2} >
                      <Grid item sm={12}>
                        <FormControl fullWidth>
                          <InputLabel id="event">Selecione o evento:</InputLabel>
                          <Select
                              id="event"
                              name="select_event"
                              label="Selecionar Evento"
                              fullWidth
                              onChange={(e) => getEventById(e.target.value)}
                              >
                                {newEvents.events.map((form) => {
                                    return(
                                        <MenuItem value={form.id}>{form.attributes.name}</MenuItem>
                                    )
                                    })
                                } 
                          </Select>
                        </FormControl>
                      </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color="primary" onClick={() => setOpenEvent(false)}>Avançar</Button>
                </DialogActions>
          </Dialog>
        </Container>
        </>
    );
  }