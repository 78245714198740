import { BrowserRouter, Routes as Rotas, Route} from 'react-router-dom';
import RequireAuth from '../helpers/RequireAuth'

import InitalPage from '../pages/index'
import Login from '../pages/Login'; 
import Dashboard from '../pages/Dashboard';
import Evento from '../pages/Evento';
import Financeiro from '../pages/Financeiro';
import Inscritos from '../pages/Inscritos';
import Config from '../pages/Config';
import Certidoes from '../pages/Certidoes';
import Payment from '../pages/Financeiro/Payment'
import Frequencia from '../pages/Frequencia';

import Sair from '@mui/icons-material/Logout';
import Dash from '@mui/icons-material/Dashboard';
import Event from '@mui/icons-material/Event';
import LocalAtm from '@mui/icons-material/LocalAtm';
import People from '@mui/icons-material/People';
import Engineering from '@mui/icons-material/Engineering';
import PostAdd from '@mui/icons-material/PostAdd'
import Checklist from '@mui/icons-material/Rule';

import EventForm from '../pages/Evento/StepAddEvent/index';
import EditForm from '../pages/Evento/StepEditEvent/index';

export const stack = [
  { path: 'Dashboard', screen: <Dashboard/>, icon:  <Dash/> },
  { path: 'Eventos', screen: <Evento/>, icon:  <Event/> },
  { path: 'Financeiro', screen: <Financeiro/>, icon:  <LocalAtm/> },
  { path: 'Inscritos', screen: <Inscritos/>, icon:  <People/> },
  { path: 'Documentos', screen: <Certidoes/>, icon: <PostAdd/> },
  { path: 'Frequencia', screen: <Frequencia/>, icon: <Checklist/> },
  { path: 'Ajustes', screen: <Config/>, icon: <Engineering/> },
  { path: 'Logout', icon: <Sair/>}
]

export const Routes = () => {
  return (
    <BrowserRouter>
    <Rotas>
      <Route path="/" exact element={<Login/>} />
      {stack.map(item => 
        <Route path={`/${item.path}`} key={item.path} element={
        <RequireAuth>
          <InitalPage title={item.path} screen={item.screen}/>
        </RequireAuth>
          }
        />
    )}
       <Route path={"/event/add"} element={
        <RequireAuth>
          <InitalPage screen={<EventForm/>}/>
        </RequireAuth>  
          }
        />
        <Route path={"/financeiro/payment"} element={
        <RequireAuth>
          <InitalPage screen={<Payment/>}/>
        </RequireAuth>  
          }
        />
        <Route path={"/event/edit/:eventId"} element={
        <RequireAuth>
          <InitalPage screen={<EditForm/>}/>
        </RequireAuth>
          }
        />
       
   </Rotas>
   </BrowserRouter>
  )
};