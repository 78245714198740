import React, { useEffect, useState } from 'react'
import { Box, Stack, Grid, FormControl, InputLabel, MenuItem,Select, Pagination, CircularProgress, Button,Alert,Snackbar } from '@mui/material'

import {useEvent}  from '../../contexts/event.context'
import TableInfo from '../../components/TableInfo'
import api from '../../services/api'
import * as qs from 'qs';
import { format, parseISO } from 'date-fns'
import XLSX from 'xlsx'

export default function Inscritos(){
    const [subscribers, setSubscribers] = useState([])
    const [pagination, setPagination] = useState([])
    const [page, setPage] = useState(1)
    const [loading,setLoading] = useState(false)
    const [eventSelected, setEventSelected] = useState()
    const { events } = useEvent()
    const [renderExcel, setRenderExcel] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [listCategories, setListCategory] = useState([]);
    const [stateAlert, setStateAlert] = React.useState({status:false, type: 'success', msg:''});
    const columnsSubscribers = [
        { Header: "Instituiçao", accessor: "company", width: "25%" },
        { Header: "Cidade", accessor: "locale", width: "20%" },
        { Header: "UF", accessor: "uf", width: "20%" },      
        { Header: "Nome", accessor: "name", width: "20%" }, 
        { Header: "Cracha", accessor: "badge", width: "20%" }, 
        { Header: "Email", accessor: "email" },
        { Header: "Whatsapp", accessor: "whatsapp", width: "20%" },
        { Header: "CPF", accessor: "document", width: "10%" }
      ];

   async function changePage(page){
       await handleSubscribers(page)
       setPage(page)
    }

    async function handleExcel(){
        if(setEventSelected.length <= 0){
            setStateAlert({...stateAlert, status:true, type: 'warning', msg: `Nenhum evento selecionado para gerar planilha!`})
            return
        }else if(subscribers.length <=0){
            setStateAlert({...stateAlert, status:true, type: 'warning', msg: `Nenhuma lista de inscritos para gerar planilha!`})
            return
        }else{
            setRenderExcel(true)
        let params = {
            sort: ['createdAt:desc'],
            pagination: {
                limit: -1
            },
            filters:{
                deleted: {
                    $eq: false
                },
                event: {
                    name:  eventSelected
                },
                plan: {
                    type: {
                        $contains: selectedCategory
                    }
                },
            },  
            populate: {
                event: {
                    fields: ['name']
                },
                plan: {
                    fields: ['type']
                },
                company: {
                    fields: ['city','state','social_reason']
                },
                subscribers: { 
                    populate: '*'
                }
            },
        encodeValuesOnly: true}
        let query = qs.stringify(params,{ skipNulls: false, 
            skipUndefined: false,
            skipEmptyStrings: true
         })
       
        const dataSheet = await api.get(`/orders/?${query}`)
        let sheetData = []
        const subs = [...dataSheet.data.data]
                // eslint-disable-next-line array-callback-return
        subs.map((subscriber,index) => {
            subs[index].attributes.subscribers.data.map(sub => {
              sheetData.push({
                CODIGO: sub.id,
                INSTITUICAO: subs[index].attributes.company.data.attributes.social_reason.toUpperCase(),
                EVENTO: subs[index].attributes.event.data.attributes.name.toUpperCase(),
                CIDADE:  subs[index].attributes.company.data.attributes.city.toUpperCase(),
                CATEGORIA: subs[index].attributes.plan.data.attributes.type.toUpperCase(),
                UF: subs[index].attributes.company.data.attributes.state.toUpperCase(),
                NOME: sub.attributes.name.toUpperCase(),
                CRACHA: sub.attributes.badge ? sub.attributes.badge .toUpperCase() : null,
                EMAIL: sub.attributes.email,
                WHATSAPP: sub.attributes.whatsapp,
                CPF: sub.attributes.document,
                data_de_inscricao: format(parseISO(subscriber.attributes.createdAt),'dd/MM/yy HH:mm'),
                })
            return
        })
        })
        const sheet = XLSX.utils.json_to_sheet(sheetData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook,sheet,'inscricoes');
        setRenderExcel(false)
        return await XLSX.writeFile(workBook,'insricoes.xlsx')
        }
    }

    async function handleSearch({event, category}){ 
        setEventSelected(event)

        setLoading(true)
        let query = qs.stringify({
            filters:{
                deleted: {
                    $eq: false
                },
                event: {
                    name:  eventSelected
                },
                plan: {
                    type: {
                        $contains: category
                    }
                },
            },
            sort: ['createdAt:desc'],
            populate: {
                event: {
                    fields: ['name']
                },
                plan:{
                    fields: ['type']
                },
                company: {
                    fields: ['city','state','social_reason']
                },
                subscribers: { 
                    populate: '*'
                }
            },
            pagination: {
                pageSize: 10,
                page: page,
            },
            encodeValuesOnly: true,
    
        })
        if(event === undefined || !event) return
        try{
            const foundedSubscribers = await api.get('/orders?'+query)
            setPagination(foundedSubscribers.data.meta.pagination)
            let subs = []
            let subscribersOrder = []
            if(foundedSubscribers.status === 200){
                subs = [...foundedSubscribers.data.data]
                // eslint-disable-next-line array-callback-return
                subs.map((subscriber,index) => {
                    subs[index].attributes.subscribers.data.map(sub => {
                        sub.attributes.event   = subs[index].attributes.event.data.attributes.name
                        sub.attributes.locale  =  subs[index].attributes.company.data.attributes.city
                        sub.attributes.uf  =  subs[index].attributes.company.data.attributes.state
                        sub.attributes.company = subs[index].attributes.company.data.attributes.social_reason
                        subscribersOrder.push(sub)
                    }) 
                })
                setSubscribers([...subscribersOrder])
                setLoading(false)
            }
        }catch(error){
            console.log(error)
            setLoading(false)
        }
    }
    
    async function handleSubscribers(nextPage){
        setLoading(true)
        let query = qs.stringify({
            filters:{
                deleted: {
                    $eq: false
                },
                event: {
                    name:  eventSelected
                },
            },
            sort: ['createdAt:desc'],
            populate: {
                event: {
                    fields: ['name']
                },
                company: {
                    fields: ['city','state','social_reason']
                },
                plans: {
                    populate: '*'   
                },
                subscribers: { 
                    populate: '*'
                }
    
            },
            pagination: {
                pageSize: 10,
                page: nextPage || page,
            },
            encodeValuesOnly: true,
    
        })
        try{
            const foundedSubscribers = await api.get('/orders?'+query)
            setPagination(foundedSubscribers.data.meta.pagination)
            let subs = []
            let subscribersOrder = []
            if(foundedSubscribers.status === 200){
                subs = [...foundedSubscribers.data.data]
                // eslint-disable-next-line array-callback-return
                subs.map((subscriber,index) => {
                    subs[index].attributes.subscribers.data.map(sub => {
                        sub.attributes.event   = subs[index].attributes.event.data.attributes.name
                        sub.attributes.locale  =  subs[index].attributes.company.data.attributes.city.toUpperCase()
                        sub.attributes.uf  =  subs[index].attributes.company.data.attributes.state.toUpperCase()
                        sub.attributes.company = subs[index].attributes.company.data.attributes.social_reason.toUpperCase()
                        sub.attributes.badge  =  sub.attributes.badge ? sub.attributes.badge .toUpperCase() : "------"
                        subscribersOrder.push(sub)
                    }) 
                })
                setSubscribers([...subscribersOrder])
                setLoading(false)
            }
        }catch(error){
            console.log(error)
            setLoading(false)
        }
    }
    
    useEffect(() => {
        if(eventSelected && eventSelected?.length > 0){
        events.map(evento => {
            if(evento.attributes.name == eventSelected ){
                setListCategory(evento.attributes.plans.data)
            }
        })
        }
    },[eventSelected])

    useEffect(()=>{
        handleSubscribers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Box sx={{width:'100%', height: '100vh'}} p={3}>
            <Stack direction="row" alignItems="center" justifyContent={'flex-start'} spacing={2} mx={9}>
            <Snackbar
                open={stateAlert.status}
                autoHideDuration={2500}
                anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
                onClose={() => setStateAlert({status: false, type: 'success', msg:'' } )} >
                    <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                        {stateAlert.msg}
                    </Alert>
            </Snackbar>
            <FormControl sx={{minWidth: '40%'}}>
                <InputLabel id="select-events">Selecionar Evento:</InputLabel>
                <Select
                    value={eventSelected}
                    onChange={(event) => { 
                            setPage(1)
                            handleSearch({event: event.target.value, category: null})
                        }
                    }
                    label="Selecionar Evento:"
                    >
                    {events.map((event) => {
                        return (
                            <MenuItem value={event.attributes.name}>{event.attributes.name}</MenuItem>
                        )
                    })}    
                </Select>
                </FormControl>
                <FormControl fullWidth >
                <InputLabel id="select-category">Selecionar Categoria:</InputLabel>
                    <Select
                        labelId="select-category"
                        id="select-category"
                        value={selectedCategory}
                        onChange={(event) => { 
                                setSelectedCategory(event.target.value);
                                handleSearch({event: eventSelected, category: event.target.value}) 
                            } 
                        }
                        label="Selecionar Categoria"
                        >
                            <MenuItem value=""> 
                                <em>Nenhum</em>
                            </MenuItem>
                            {listCategories.length > 0  && listCategories.map(plan => 
                                <MenuItem key={plan.id} value={plan.attributes.type}>{plan.attributes.type}</MenuItem>                              
                            )}
                    </Select>
                </FormControl>
                <Button sx={{width: 150}} variant='contained' color='success' onClick={()=> handleExcel()} disabled={renderExcel}> 
                    {renderExcel ? <CircularProgress sx={{ alignSelf:'center'}} size={20}/> : "Exportar para Excel"} </Button>
            </Stack>
            <Grid container py={3}>
            {loading ? 
                <Box sx={{ width:'100%', height: '10vh',display: 'flex', justifyContent:'center' }}>
                    <CircularProgress sx={{ alignSelf:'center'}} size={50}/>
                </Box> 
             :
                <TableInfo title='Inscritos' variant="info" rows={subscribers} columns={columnsSubscribers} />
            }
            </Grid>

            <Stack direction="row" alignItems="center" justifyContent={'flex-end'} spacing={2} mx={10}>
                <Pagination count={pagination.pageCount} defaultPage={1} color="primary" 
                    onChange={(event,value) => changePage(value)}/>
            </Stack>
        </Box>
    )
}