import axios from "axios";
import { getToken } from "./auth";
const token = getToken();

export const baseURL = "https://api.sempereventos.com.br"
const api = axios.create({
   baseURL: "https://api.sempereventos.com.br/api/"
})
api.interceptors.request.use(async config => {
  if (token) {
    config.headers.Authorization = "Bearer "+token+"" ;
  }
  return config;
});
export default api;