import React, { createContext, useState,  useContext } from 'react';

const EventContext = createContext({});

export const EventProvider = ({ children }) => {
    const [events, setEvents] = useState([]);    

    return (
      <EventContext.Provider
        value={{ events, setEvents }}>
        {children}
      </EventContext.Provider>
    );
  };
  
  export function useEvent() {
    const context = useContext(EventContext);
  
    return context;
  }
export default EventContext;