import React from 'react'

export const CompanyContext = React.createContext({
    company: [{ 
        document: String,
        email: String,
        address: String,
        phone: String,
        whatsapp: String,
        trade: String,
        number: String,
        social_reason: String,
        complement: String,
        cep: String,
        city: String,
        district: String
    }],
    setCompany: () => {}
});

export function CompanyContextProvider({children}) {
   const [company, setCompany] = React.useState([])


   return <CompanyContext.Provider value={{company,setCompany}}>{children}</CompanyContext.Provider>
 }