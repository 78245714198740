import React, { createContext, useState,  useContext } from 'react';

const NavigationContext = createContext({});

export const NavigationProvider = ({ children }) => {
    const [page, setPage] = useState(null);
    const [link, setLink] = useState(null); 

   async function loadBack(){
      let history = localStorage.getItem('back')
      return history
   }
   async function nextPage(path){
      localStorage.setItem('back',path)
   }

    return (
      <NavigationContext.Provider
        value={{ page, setPage, link, setLink, loadBack, nextPage }}
      >
        {children}
      </NavigationContext.Provider>
    );
  };
  
  export function useNavigation() {
    const context = useContext(NavigationContext);
  
    return context;
  }
export default NavigationContext;