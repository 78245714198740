import React from 'react'
import { Typography, Box, Grid, Button, Snackbar, CircularProgress, Dialog, DialogTitle,
    DialogActions, DialogContent, TextField, FormControlLabel, Checkbox,Switch } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import TableUsers from '../../components/TableUsers';
import api from '../../services/api';
import { stack } from '../../routes/index'
import * as yup from 'yup';
import { useFormik } from 'formik';
import * as qs from 'qs';
import { parseISO, format } from 'date-fns'

export default function Config(){
    const [stateAlert, setStateAlert] = React.useState({status:false, type: 'success', msg:''});
    const [listUsers, setListUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const query = React.useRef()

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const columns = [
        { Header: "Usuário", accessor: "username", width: "20%" },
        { Header: "E-mail", accessor: "email", width: "20%" },
        { Header: "Bloqueado", accessor: "blocked", width: "10%" },
        { Header: "Criado em", accessor: "createdAt", width: "10%" },
        { Header: "Atualizado em", accessor: "updatedAt", width: "10%" },
        { Header: "Permissões", accessor: "permissions", width: "20%" },
        { Header: "Ações", accessor: "actions", width: "20%" },
    ];
    query.current = qs.stringify({
        filters:{
            role: {
                name: {
                    $eq: 'Operators'
                }
            }
        },
        sort: ['createdAt:desc'],
        populate: '*',
        encodeValuesOnly: true,

    })
    const validationUser= yup.object({
        username: yup
            .string('Insira o nome do usuário')
            .required('Campo necessário'),
        email: yup
            .string('Insira um email válido')
            .required('Campo necessário'),
        blocked: yup
            .boolean(),
        permissions: yup.object({
                Ajustes: yup.boolean().notRequired(),
                Eventos: yup.boolean().notRequired(),
                Dashboard: yup.boolean().notRequired(),
                Inscritos: yup.boolean().notRequired(),
                Documentos: yup.boolean().notRequired(),
                Financeiro: yup.boolean().notRequired(),
                Logout: yup.boolean().notRequired()
        }).notRequired()
    })
    const validationEditUser= yup.object({
        id: yup
            .number()
            .required(),
        username: yup
            .string('Insira o nome do usuário')
            .required('Campo necessário'),
        email: yup
            .string('Insira um email válido')
            .required('Campo necessário'),
        password: yup 
            .string()
            .notRequired(),
        permissions: yup.object({
                Ajustes: yup.boolean().notRequired(),
                Eventos: yup.boolean().notRequired(),
                Dashboard: yup.boolean().notRequired(),
                Inscritos: yup.boolean().notRequired(),
                Documentos: yup.boolean().notRequired(),
                Financeiro: yup.boolean().notRequired(),
                Logout: yup.boolean().notRequired()
        }).notRequired()
    })
    function editUser({id}){
        try{
            const selectedUser = listUsers.filter(user => id == user.id)
            console.log(selectedUser)
            formEditUsers.setFieldValue('username', selectedUser[0].username)
            formEditUsers.setFieldValue('id', selectedUser[0].id)
            formEditUsers.setFieldValue('blocked', selectedUser[0].blocked)
            formEditUsers.setFieldValue('email', selectedUser[0].email)
            Object.keys(selectedUser[0].permissions).map((el,index) => formEditUsers.setFieldValue(`permissions.${el}`, Object.values(selectedUser[0].permissions)[index]) )
            setEditOpen(true)
        }catch(error){
            setStateAlert({...stateAlert, status:true,type:'error',msg:`${error.message}`})
        }
    }
    async function deleteUser({id}){
        try{
            const deletedUser = await api.delete('/users/'+id)
            if(deletedUser.status == 200){
                setStateAlert({...stateAlert, status:true,type:'success',msg:'Usuário deletado com sucesso'})
                await handleUsers()
            }
        }catch(error){
            setStateAlert({...stateAlert, status:true,type:'error',msg:`${error.message}`})
        }
    }
    async function sendUser(data){
        try{
            const userCreated = await api.post('/users', data)
            if(userCreated.status === 201){
                setStateAlert({...stateAlert, status:true,type:'success',msg:'Usuário cadastrado com sucesso'})
                await handleUsers()
                formUsers.handleReset()
                setDialogOpen(false)
            }
        }catch(error){
            setStateAlert({...stateAlert, status:true,type:'error',msg:`${error.message}`})
        }
    }
    async function updateUser(data){
        try{
            const userUpdated = await api.put('/users/'+data.id, data)
            if(userUpdated.status === 200){
                setStateAlert({...stateAlert, status:true,type:'success',msg:'Usuário atualizado com sucesso'})
                await handleUsers()
                formEditUsers.handleReset()
                setEditOpen(false)
            }
        }catch(error){
            setStateAlert({...stateAlert, status:true,type:'error',msg:`${error.message}`})
        }
    }
    const formEditUsers = useFormik({
        initialValues:{id:'',username:'',email:'',confirmed: true, role:3,blocked: false, permissions: {Ajustes: false, Eventos:false,Dashboard:false,Inscritos:false,Documentos:false,Financeiro:false,Logout:true}},
        validateOnMount:true,
        validationSchema: validationEditUser,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values)
            updateUser(values)
        },
    });
    const formUsers = useFormik({
        initialValues:{username:'',email:'',password:'',confirmed: true, role:3,blocked: false, permissions: {Ajustes: false, Eventos:false,Dashboard:false,Inscritos:false,Documentos:false,Financeiro:false,Logout:true}},
        validateOnMount:true,
        validationSchema: validationUser,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values)
            sendUser(values)
        },
    });

    async function handleUsers(){
        try{
            const users = await api.get(`users/?${query.current}`)
            if(users.status === 200){
                users.data.map( user => {
                    user.createdAt = format(parseISO(user.createdAt),'dd/MM/yy HH:mm:ss')
                    user.updatedAt = format(parseISO(user.updatedAt),'dd/MM/yy HH:mm:ss')
                })
                setListUsers(users.data)
                setLoading(false)
            }
        }catch(error){  
            setStateAlert({...stateAlert, status:true,type:'error',msg:`${error.message}`})
            setLoading(false)
        }
    }

    React.useEffect(()=> { handleUsers() },[])

    return (
        <Box sx={{width:'100%', height: '100vh'}} p={5}>
        <Grid container px={12} sx={{justifyContent: 'space-between'}}>
            <Grid item xs={6}>
                <Typography variant='h4'>Configurações do Sistema</Typography>
            </Grid>
        </Grid>
        <Snackbar
            open={stateAlert.status}
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            onClose={() => setStateAlert({status: false, type: 'success', msg:'' } )} >
                <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                    {stateAlert.msg}
                </Alert>
        </Snackbar>
            <Dialog
                open={dialogOpen}
                maxWidth={'lg'}
                aria-describedby="edit-dialog" >
                <DialogTitle>Adicionar Usuário</DialogTitle>
                <form onSubmit={formUsers.handleSubmit}>
                <DialogContent>
                    <Grid container spacing={2} columns={12} sx={{justifyContent: 'center'}}>
                        <Grid item xs={12} lg={12}>
                            <TextField sx={{width: '100%'}} 
                                label="Nome do Usuário"  
                                id='username'
                                name="username" 
                                variant="outlined"
                                value={formUsers.values.username} 
                                onChange={formUsers.handleChange}
                                error={formUsers.touched.username && Boolean(formUsers.errors.username)}
                                helperText={formUsers.touched.username && formUsers.errors.username}
                                placeholder="Nome de Usuário"
                                />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField sx={{width: '100%'}} 
                                label="Email"  
                                id='email'
                                name="email" 
                                variant="outlined"
                                value={formUsers.values.email} 
                                onChange={formUsers.handleChange}
                                error={formUsers.touched.email && Boolean(formUsers.errors.email)}
                                helperText={formUsers.touched.email && formUsers.errors.email}
                                placeholder="Email do Usuário"
                                />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField sx={{width: '100%'}} 
                                label="Senha"  
                                id='password'
                                name="password" 
                                type={'password'}
                                variant="outlined"
                                value={formUsers.values.password} 
                                onChange={formUsers.handleChange}
                                error={formUsers.touched.password && Boolean(formUsers.errors.password)}
                                helperText={formUsers.touched.password && formUsers.errors.password}
                                placeholder="Senha do Usuário"
                                />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Typography py={2} variant={'h6'}>Módulos permitidos:</Typography>
                            {stack.map(el => {
                                if(el.path == 'Logout') return
                                return(
                                    <FormControlLabel
                                    key={el.path}
                                    name={`permissions.${el.path}`}
                                    checked={formUsers.values.permissions[`${el.path}`]}
                                    value={formUsers.values.permissions[`${el.path}`]}
                                    onClick={(e) => {
                                            let val = !formUsers.values.permissions[`${el.path}`]
                                            console.log('value',e.target.value, val)
                                            console.log(e.target.name)
                                            formUsers.setFieldValue(e.target.name, val)
                                            console.log('formik',formUsers.values)
                                    }}
                                    control={<Checkbox />}
                                    label={el.path}
                                    labelPlacement="top"
                                />
                            )}
                            )}
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => { setDialogOpen(false); formUsers.handleReset() }} color="error">Cancelar</Button>
                     <Box sx={{ m: 1, position: 'relative' }}>
                                <Button variant="contained" color='success' type={'submit'} fullWidth >Salvar</Button>
                            </Box> 
                </DialogActions>
                </form>
            </Dialog>
            <Dialog
                open={editOpen}
                maxWidth={'lg'}
                aria-describedby="edit-dialog" >
                <DialogTitle>Editar Usuário</DialogTitle>
                <form onSubmit={formEditUsers.handleSubmit}>
                <DialogContent>
                    <Grid container spacing={2} columns={12} sx={{justifyContent: 'center'}}>
                        <Grid item xs={12} lg={12}>
                            <TextField sx={{width: '100%'}} 
                                label="Nome do Usuário"  
                                id='username'
                                name="username" 
                                variant="outlined"
                                value={formEditUsers.values.username} 
                                onChange={formEditUsers.handleChange}
                                error={formEditUsers.touched.username && Boolean(formEditUsers.errors.username)}
                                helperText={formEditUsers.touched.username && formEditUsers.errors.username}
                                placeholder="Nome de Usuário"
                                />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField sx={{width: '100%'}} 
                                label="Email"  
                                id='email'
                                name="email" 
                                variant="outlined"
                                value={formEditUsers.values.email} 
                                onChange={formEditUsers.handleChange}
                                error={formEditUsers.touched.email && Boolean(formEditUsers.errors.email)}
                                helperText={formEditUsers.touched.email && formEditUsers.errors.email}
                                placeholder="Email do Usuário"
                                />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField sx={{width: '100%'}} 
                                label="Senha"  
                                id='password'
                                disabled
                                name="password" 
                                type={'password'}
                                variant="outlined"
                                placeholder="Senha do Usuário"
                                />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Typography px={4} variant='body'>Bloquear Usuário:</Typography>
                            <FormControlLabel
                                name="blocked"
                                checked={formEditUsers.values.blocked}
                                value={formEditUsers.values.blocked} 
                                onChange={formEditUsers.handleChange}
                                control={<Switch />}
                                label={formEditUsers.values.blocked ? 'Usuário Bloqueado' : 'Usuário Ativo'}
                                labelPlacement="right"
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Typography py={2} variant={'h6'}>Módulos permitidos:</Typography>
                            {stack.map(el => {
                                if(el.path == 'Logout') return
                                return(
                                    <FormControlLabel
                                    key={el.path}
                                    name={`permissions.${el.path}`}
                                    checked={formEditUsers.values.permissions[`${el.path}`]}
                                    value={formEditUsers.values.permissions[`${el.path}`]}
                                    onClick={(e) => {
                                            let val = !formEditUsers.values.permissions[`${el.path}`]
                                            console.log('value',e.target.value, val)
                                            console.log(e.target.name)
                                            formEditUsers.setFieldValue(e.target.name, val)
                                            console.log('formik',formEditUsers.values)
                                    }}
                                    control={<Checkbox />}
                                    label={el.path}
                                    labelPlacement="top"
                                />
                            )}
                            )}
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => { setEditOpen(false); formEditUsers.handleReset() }} color="error">Cancelar</Button>
                     <Box sx={{ m: 1, position: 'relative' }}>
                                <Button variant="contained" color='success' type={'submit'} fullWidth >Salvar</Button>
                            </Box> 
                </DialogActions>
                </form>
            </Dialog>
            <Grid container px={8} py={4} justifyContent={'end'}>
                <Grid item xs={6} md={2} >
                    <Button variant='outlined' color='primary' onClick={()=> setDialogOpen(true)}>Adicionar Usuário</Button>
                </Grid>
            </Grid>
            <Grid container px={8} py={2}>
                <Grid item xs={12} md={12}>
                {loading ?
                    <Box sx={{ width:'100%', height: '10vh',display: 'flex', justifyContent:'center' }}>
                        <CircularProgress    sx={{ alignSelf:'center'}} size={50}/>
                    </Box> 
                    :
                    <TableUsers title='Controle de Usuários' variant="secondary" deactive={deleteUser} edit={editUser} rows={listUsers} columns={columns} />
                }
                </Grid>
            </Grid>
        </Box>
    )
}