import { format } from 'date-fns'

export const eventStartDTO = {
    name: '',
    isActive: true,
    start_subscription: format(new Date(), 'yyyy-MM-dd'),
    end_subscription: format(new Date(), 'yyyy-MM-dd'),
    max_subscribers: null,
    plans: [],
    position: null,
    collectors: [],
    certificate: '',
    city: '',
    uf: '',
    date_initial: null,
    date_finish: null,
    thumbnail: '',
    apresentation:'',
    terms:'',
    location:'',
    location_address:'',
    scheduling:'',
    information_accommodation: '',
    message_confirmation: 'A AGIP confirma a inscrição do servidor { INSCRITO } para participar do { EVENTO } que ocorrerá na { DATA }. Aguardamos a confirmação de pagamento.'
}